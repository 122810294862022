import { Flex } from '@chakra-ui/react'
import { useState } from 'react'

import { Ambition, FAQ, FakeHeaderHero, Form, HowItWorks, ProjectCall } from '@components'
import { TabContext } from '@components/TabContext'

const Home = () => {
  const [selectedTab, setSelectedTab] = useState('holiday')

  return (
    <Flex direction="column">
      <FakeHeaderHero />
      <TabContext.Provider value={{ selectedTab, setSelectedTab }}>
        <Ambition />
        <HowItWorks />
        <ProjectCall />
      </TabContext.Provider>
      <Form />
      <FAQ />
    </Flex>
  )
}

export default Home
